.cookies-config-modal {
  color: black;
}

.cookies-box {
  color: black;
}
.cookies-box a {
  text-decoration: underline;
  color: #006dff;
}

.cookies-box {
  width: 90vw;
  margin: 0 auto;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 5em;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column;
  flex-flow: column;
  font-size: small;
  padding: 20px;
  z-index: 1025;
  box-shadow: 2px 2px 33px rgba(0,0,0,.5);
  background-color: #fff;
  border-radius: .2em;
  transition: transform .4s ease-out,opacity .4s ease-out,visibility 0.5s;
}

.cookies-box button {
  border-radius: 2em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

@media only screen and (min-width: 64em) {
  .cookies-box {
    width: 80%;
    bottom: 0.5em;
    font-size: large;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .cookies-box button {
    margin: 1em 1em 1em 2em;
    max-height: 4em;
  }
}